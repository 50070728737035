import React, { useEffect } from "react";
// import loader from "../../assets/images/Loader.gif";
// import loader from "../../assets/images/loaderVideo.mp4";
import loader from "../../assets/images/newLoader.mp4";
import useLoader from "./useLoader";
import "./styles.css";
// import useImageLoader from "../../apis/services/useImageLoader";
// import { ProgressBar } from "react-bootstrap";

const Loader = ({ init }) => {

  const { loading, count, setCount, isFromBot } = useLoader();
  // const { onImageLoaded } = useImageLoader(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prev) => {
        if (prev >= 90 && count !== 90) {
          clearInterval(intervalId);
          return prev;
        } else if (prev < 90 && count !== 100) {
          return prev + 10;
        } else {
          clearInterval(intervalId);
          return prev;
        }
      });
    }, 1500);
    return () => clearInterval(intervalId);
  }, []);

  if (init || loading)
    return (
      <div className={
        true ? "loader-container bg-black" : 
        "loader-container bg-white"}>
        <div className="inner" style={{color:'#0074a7'}}>
          {/* <Spinner animation="border" variant="primary" /> */}
          {/* <img src={loader} alt="loader" width={120} /> */}
          <video width="200" height="200" preload="none" loading='lazy' autoPlay loop muted playsInline className="rounded-circle">
            <source style={{borderRadius:'10px'}} src={loader} type="video/mp4" />
          </video>
          <div className="progress-count">
            {/* <ProgressBar now={count} max={100} min={0} label={`${count}%`} /> */}
            <div className="progress mt-md-3" style={{height:5}}>
              <div
                role="progressbar"
                className="progress-bar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${count}%`, height:5 }}
              ></div>
            </div>
          </div>
              {`${count}%`}
        </div>
      </div>
    );
  else if (!loading ) return null;
};

export default Loader;
