import { useContext } from "react";
import { LoaderContext } from "../../contexts/loaderContext";

const useLoader = () => {
  const { points, loading, setLoader,setProgessCount,count,setRedirectToTool,isRedirectTool, isFromBot , setIsFromBots} = useContext(LoaderContext);
  const setLoading = (status) =>  setLoader(status);
  const setIsFromBot = (status) =>  setIsFromBots(status);
  const setCount = (count) => setProgessCount(count);
  const setRedirectTool = (redirect) =>  setRedirectToTool(redirect)
  return { loading, points,count,isRedirectTool, isFromBot, setLoading,setCount,setRedirectTool ,setIsFromBot};
};

export default useLoader;
