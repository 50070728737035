import axios from "axios";

export const BOT_URL = "https://obot.odineducation.co.za/dashboard";
export const PORTAL_API_URL = "https://odineducationlive-api.cubettech.in/api/";
export const NEW_API_URL = "https://obot-api.odineducation.co.za/";
export const SERVER_URL = "https://odineducationlive-api.cubettech.in/";

export const GG_API = "AIzaSyClrULWWMKAxzpwxxdUSyjwpXZKVz9LI40";
export const ReCAPTCHA_API = "6Lc4dGAiAAAAAO2JwPls9u07-UQfArsJbgX5rimv";

// export default axios.create({
//   baseURL: PORTAL_API_URL,
//   headers: {
//     Accept: "application/json",
//   },
// });

export const newApi = axios.create({
  baseURL: NEW_API_URL,
  headers: {
    Accept: "application/json",
  },
});

const defaultApi = axios.create({
  baseURL: PORTAL_API_URL,
  headers: {
    Accept: "application/json",
  },
});
export default defaultApi;
